<template>
  <div class="vps-context-menu">
    <div v-for="(group, i) in menu" :key="i" class="vps-context-menu__group">
      <component
        :is="item.attrs ? 'a' : 'div'"
        v-for="item in group"
        :key="item.key"
        v-bind="item.attrs"
        class="vps-context-menu__item standart-title"
        :class="{ [`vps-context-menu__item--${item.color}`]: !!item.color }"
        :disabled="item.disabled"
        @click="onClick(item)"
      >
        {{ $t(`actions.${item.key}`) }}
      </component>
    </div>
  </div>
</template>

<script>
import { VpsIpTariff } from '@/models/BillMgr/VpsIpTariff';
import BaseLoader from '@/components/BaseLoader/BaseLoader';
import showErrorModal from '@/mixins/showErrorModal';
import modals from '@/mixins/modals';
import updateServerInfo from '@/mixins/updateServerInfo';
import createBackup from '@/mixins/backup';
import Ip from '@/layouts/VPS/components/Ip';
export default {
  name: 'VpsIpContextMenu',
  mixins: [showErrorModal, updateServerInfo, createBackup, modals],
  props: {
    tariff: {
      type: VpsIpTariff,
      required: true,
      validator: obj => obj instanceof VpsIpTariff,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      domain: '',
    };
  },
  computed: {
    status() {
      return this.tariff.status.code;
    },
    model() {
      return this.$store.getters['moduleVPS/getModels'];
    },
    menu() {
      const arr = [];
      arr.push([
        {
          key: 'service.ip.edit',
          handler: 'func',
        },
        {
          key: 'service.ip.delete',
          color: 'del',
          disabled: this.tariff.no_delete === 'on',
          handler: 'func',
        },
      ]);
      return arr;
    },
    provider() {
      return this.$store.getters['moduleProviders/current'];
    },
    id() {
      return this.$store.getters['moduleVPS/GET_CURRENT'];
    },
  },
  watch: {
    caravan() {
      return this.provider.id === '2';
    },
  },
  mounted() {},
  methods: {
    onClick(item) {
      if (item.disabled) return;
      if (item.key === 'service.ip.edit') return this.editIp();

      if (item.key === 'service.ip.delete') return this.removeIp();
    },
    editIp() {
      let self = null;
      const params = {
        elid: this.tariff.id,
        plid: this.id,
      };
      this.$store.dispatch('moduleVPS/editIdDetail', params);
      // const params = {
      //   elid: this.tariff.id,
      //   plid: this.id,
      //   func: 'service.ip.edit',
      // };
      // this.$store
      //   .dispatch('fetchBillMgrToolAction', params)
      //   .then(data => {
      //     this.model = data.model;
      // return this.fillWhois(data.model.body);
      // })
      // .catch(e => this.showError(e));
      this.$modals.open({
        name: 'Ip',
        title: `IP-адрес ${this.tariff.name} (${this.tariff.domain})`,
        size: 'big',

        component: Ip,
        closeOnBackdrop: false,
        props: {
          tariff: this.tariff,
          id: this.id,
        },
        on: {
          send: event => {
            this.domain = event;
          },
        },
        onOpen: instance => (self = instance),
        onClose: () => {
          self = null;
        },
        footer: {
          centered: true,
          confirm: {
            props: {
              title: this.$t('good'),
            },
            on: {
              // click: () => {
              //   this.sendDomain();
              //   setTimeout(() => {
              //     this.$store
              //       .dispatch('moduleVPS/fetchId', +this.id)
              //       .then(() => this.$modals.close());
              //   }, 1000);
              // },
              click: () => {
                self.text = null;
                let res = '';
                let time;
                // this.sendDomain();
                self.component = BaseLoader;
                self.closable = false;
                self.footer = false;
                // this.oldState = action !== 'restart' ? this.serverState.toString() : 'processing';
                time = Date.now();
                this.isProcessing = true;
                // this.$store
                //   .dispatch('moduleVPS/fetchId', +this.id)
                this.sendDomain()
                  .then(() => {
                    res = 'ok';
                    // this.$store
                    //     .dispatch('moduleVPS/fetchId', +this.id)
                    // this.updateServerInfo();
                  })
                  .catch(() => {
                    res = 'fail';
                  })
                  .finally(() => {
                    const delay = Date.now() - time < 1000 ? 1000 : 0;
                    setTimeout(() => {
                      self.component = null;
                      self.closable = true;
                      self.text = this.$t(`modal.sure.${res}`);
                      self.footer = {
                        centered: true,
                        confirm: {
                          props: { title: this.$t('modal.sure.close') },
                          on: {
                            click: () => {
                              this.$store.dispatch('moduleVPS/fetchId', +this.id);
                              this.$modals.close();
                            },
                          },
                        },
                      };
                    }, delay);
                  });
              },
            },
          },
        },
      });
    },
    deleteIp(id) {
      const params = {
        func: 'service.ip.delete',
        plid: this.id,
        elid: id,
        newface: 'on',
      };
      return this.$store.dispatch('sendBillMgrToolAction', params, { root: true });
    },
    async removeIp() {
      let names = this.tariff.name;
      let msg = this.$t('removeOrders.text') + ' ' + names;
      const isSure = await this.askConfirm(msg);
      if (this.sureModal) this.$modals.close({ name: this.sureModal.name });
      if (isSure) {
        this.$modals.open({
          name: 'RemoveOrders',
          component: BaseLoader,
          closable: false,
          onOpen: inst => (this.removeOrderModal = inst),
          onClose: () => (this.removeOrderModal = null),
        });
        this.deleteIp(this.tariff.id);
        this.$store.dispatch('moduleVPS/fetchId', +this.id).then(() => this.$modals.close());
      }
    },
    askConfirm(msg) {
      return new Promise(resolve => {
        this.$modals.open({
          name: 'AskConfirm',
          title: this.$t('askConfirm.title'),
          text: this.$t('askConfirm.text', { msg }),
          onOpen: inst => (this.sureModal = inst),
          onClose: () => (this.sureModal = null),
          onDismiss: () => resolve(false),
          footer: {
            confirm: {
              props: { title: this.$t('askConfirm.confirm') },
              on: {
                click: () => resolve(true),
              },
            },
            cancel: {
              props: { title: this.$t('askConfirm.cancel') },
              on: {
                click: () => resolve(false),
              },
            },
          },
        });
      });
    },
    sendDomain() {
      const params = {
        ...this.model,
        plid: this.id,
        domain: this.domain,
        func: 'service.ip.edit',
      };

      return this.$store.dispatch('sendBillMgrToolAction', params).catch(e => {
        this.showError(e);
      });
    },
  },
};
</script>

<i18n>
{
  "ru": {
    "askConfirm": {
      "title": "Подтвердите действие",
      "text": "Вы уверены, что хотите удалить {msg}?",
      "confirm": "Да",
      "cancel": "Отмена"
    },
    "removeOrders": {
      "text": "IP-адрес"
    },
    "actions": {
      "service":{
        "ip": {
          "edit": "изменить",
          "delete": "удалить"
        }
      },
      "stop": "выключить",
      "process": "в процессе",
      "restart": "перезагрузить",
      "panel": "Панель управления сервером",
      "plesk": "Панель Plesk",
      "console": "открыть консоль",
      "createBackup": "создать бэкап",
      "recreateServer": "пересоздать сервер",
      "removeOrder": "удалить заказ",
      "removeServer": "удалить сервер",
      "loading": "запрашиваем данные..."
    },
    "modal": {
      "sure": {
        "text": "Вы собираетесь %{msg} сервер. Вы уверены?",
        "confirm": "Да, уверен",
        "close": "Закрыть",
        "ok": "Домен успешно изменен",
        "success": "Запрос выполняется. Пожалуйста, дождитесь изменения статуса.",
        "fail": "Кажется, что-то пошло не так. Пожалуйста, попробуйте позже."
      },
      "removeFromBasket": {
        "success": "Заказ успешно удалён"
      }
    },
    "new_backup": "Создать резервную копию",
    "server_state": {
      "error_backup": "Бэкап можно сделать только на остановленном сервере!"
    }
  }
}
</i18n>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';

$vcm-colors = {
  primary: $primary-color,
  success: $success-color,
  del: $color-red.light,
}
$vcm-colors-hover = {
  primary: $color-blue.medium,
  success: darken($success-color, 20%),
  del: darken($color-red.light, 20%),
}

.vps-context-menu {

  &__group {
    & + & {
      margin-top: 2.5rem;

      +breakpoint(sm-and-up) {
        margin-top: (1.25rem * 3);
      }
    }
  }
  &__item {
    display: block;
    color: var(--text);
    text-decoration: none;
    cursor: pointer;
    transition: color 0.3s ease;

    &:first-letter {
      text-transform: uppercase;
    }

    &:focus {
      outline: none;
    }
    &:hover {
      color: $primary-color;
    }

    &[disabled] {
      pointer-events: none;
      cursor: default;
      opacity: 0.6;
    }

    & + & {
      margin-top: 1.25rem;
    }

    for $key, $value in $vcm-colors {
      &--{$key} {
        color: $value;
      }
    }
    for $key, $value in $vcm-colors-hover {
      &--{$key}:hover {
        color: $value;
      }
    }

  }
}
</style>
<style lang="stylus">
@require '~@/assets/styles/vars/variables';

.vps-context-menu {
  .action-popup & {
    padding: 1.5rem 1rem;
    width: calc(100vw - 0.75rem);

    +breakpoint(sm-and-up) {
      padding: 1.5rem;
      width: 260px;
    }
  }
}
</style>
