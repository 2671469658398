<template>
  <base-table-row
    class="tariffs-table-item"
    :columns="columns"
    v-bind="item"
    col-class="tariffs-table-item__col"
  >
    <!--    <template #server>-->
    <!--      <div class="tariff-table-item__server">-->
    <!--        <router-link-->
    <!--          :to="{ name: 'vpsDetail', params: { id: item.id } }"-->
    <!--          class="tariffs-table-item__link medium-text"-->
    <!--        >-->
    <!--          {{ item.title }}-->
    <!--        </router-link>-->
    <!--        <div class="tariffs-table-item__specs note-text">-->
    <!--          {{ specs }}-->
    <!--        </div>-->
    <!--      </div>-->
    <!--    </template>-->
    <template #ip>
      <div class="tariffs-table-item__ip note-color">
        {{ item.name }}
      </div>
    </template>
    <template #pricelist>
      <div class="tariffs-table-item__pricelist">
        {{ item.domain }}
      </div>
    </template>
    <template #active>
      <div class="tariffs-table-item__date note-color">
        {{ item.type }}
      </div>
    </template>
    <template #cpu>
      <div class="tariffs-table-item__cpu note-color">
        {{ item.ncpu }}
      </div>
    </template>
    <template #mem>
      <div class="tariffs-table-item__mem note-color">
        {{ item.mem }}
      </div>
    </template>
    <template #disc>
      <div class="tariffs-table-item__disc note-color">
        {{ item.disc }}
      </div>
    </template>
    <template #autoprolong>
      <vps-autoprolong
        v-tooltip="isAutoProlong"
        :tariff="item"
        class="tariffs-table-item__autoprolong"
      />
    </template>
    <template #status>
      <main-label :color="item.status.color" theme="plain" class="tariffs-table-item__status">
        {{ $t(`status.${item.status.key}`) }}
      </main-label>
    </template>
    <template #more>
      <v-popover
        v-click-outside="closePopoverOnOutside"
        placement="left"
        :open="isPopoverOpen"
        trigger="manual"
        popover-class="tariff-table-item__popover"
      >
        <plain-button
          icon="more"
          class="tariffs-table-item__more"
          @click="togglePopoverState"
        /><template #popover>
          <vps-ip-context-menu
            v-if="isPopoverOpen"
            :tariff="item"
            class="tariff-table-item__context-menu"
        /></template>
      </v-popover>
    </template>
  </base-table-row>
</template>

<script>
import BaseTableRow from '@/components/Table/BaseTableRow';
import MainLabel from '@/components/Labels/MainLabel.vue';
import VpsIpContextMenu from '../../../components/VpsIpContextMenu';
import VpsAutoprolong from '../../../components/VpsAutoprolong';
import VpsProlongSingle from '../../../components/VpsProlongSingle.vue';
import prolongSingle from '@/mixins/billmgr/prolongSingle';
import popoverFix from '@/mixins/popoverFix';
import { format } from 'date-fns';
export default {
  name: 'IpTableRow',
  components: { BaseTableRow, MainLabel, VpsIpContextMenu, VpsAutoprolong },
  mixins: [prolongSingle, popoverFix],
  props: {
    columns: {
      type: Array,
      required: true,
    },
    item: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      moduleMain: 'moduleVPS',
      singleProlongComponent: VpsProlongSingle,
    };
  },
  computed: {
    // isAutoProlong() {
    //   return this.item.autoprolong_id === 'null'
    //     ? 'Включить автопродление'
    //     : 'Автопродление включено';
    // },
    // formattedEndDate() {
    //   return format(this.item.expiredate, 'dd.MM.yyyy');
    // },
    // specs() {
    //   return `${this.item.ncpu}/${this.$t('specs.mem')}: ${this.item.mem}/${this.$t(
    //     'specs.disc'
    //   )}: ${this.item.disc}`;
    // },
    tools() {
      return this.$store.state.moduleVPS.tools;
    },
    // hasProlongTool() {
    //   return (
    //     this.item.prolongAvailable &&
    //     !!this.tools &&
    //     !!this.tools.prolong &&
    //     this.tools.prolong.isEnable(this.item)
    //   );
    // },
  },
  methods: {
    getLocalizedSize(val, units) {
      const GB = 1024;
      let num = val;
      let unitKey = units === 'МБ' ? 'mb' : units;
      if (unitKey === 'mb' && val >= GB) {
        unitKey = 'gb';
        num = num / GB;
      }
      return this.$t(unitKey, { num });
    },
    concatString(obj) {
      let str = obj.value;
      if (obj.unit) str += ` ${obj.unit}`;
      return str;
    },
    printParam(param, payload) {
      return param.toString(payload);
    },
  },
};
</script>

<i18n>
  {
    "ru": {
      "activeUnder": "До {date} г.",
      "mb": "{num} МБ",
      "gb": "{num} ГБ",
      "specs": {
        "cpu": "CPU",
        "mem": "RAM",
        "disc": "Disc"
      },
      "prolong": {
        "title": "Продление тарифа",
        "error": "К сожалению, что-то пошло не так",
        "success_basket": "Продление тарифа успешно добавлено в корзину",
        "success_order": "Продление тарифа прошло успешно. Данные скоро обновятся.",
        "confirm": "Оплатить",
        "order": "В корзину",
        "action": "Продлить"
      }
    }
  }
</i18n>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';
.tariffs-table-item {
  font-size: 1rem;
  text-overflow: ellipsis;
  white-space: nowrap;

  >>> &__col {
    vertical-align: middle;


    +breakpoint(md-and-down) {
      min-width: min-content;
      max-width: unset!important;
    }

    +breakpoint(ml-and-up) {
      text-overflow: ellipsis;
      overflow: hidden;
      position: relative;
      z-index: 1;
    }

    &:hover {
      background: var(--main-card-bg);
      text-overflow: unset;
      overflow: visible;
      z-index: 2;
    }
  }
    &__autoprolong {
      text-align: center;
    }
  &__link {
    text-decoration: none;
    color: $primary-color;

    &:hover {
      color: darken($primary-color, 20%);
    }
  }
  &__server {
    position: relative;
    padding: 0.5em;
    margin: -0.5em;
    white-space: nowrap;
    background: inherit;

    &:hover::after {
      +breakpoint(ml-and-up) {
        content: '';
        display: block;
        size(2.5em, 100%);
        absolute(0px, 100%);
        background: linear-gradient(to right, var(--main-card-bg) 0%, transparent 100%);
      }
    }
  }
}
</style>

<style lang="stylus">
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';
.tariff-table-item {
  &__popover {
    text-align: left
    &.tooltip[x-placement^="left"] {
      +breakpoint(ms-and-down) {
        margin-right: 0;
      }
    }
    &.tooltip[x-placement^="right"] {
      +breakpoint(ms-and-down) {
        margin-left: 0;
      }
    }
    .popover-arrow {
      +breakpoint(ms-and-down) {
        display: none;
      }
    }
  }

  &__context-menu {
    +breakpoint(ms-and-down) {
      max-width: 100%;
      width: calc(100vw - 6rem);
    }
  }
}
</style>
